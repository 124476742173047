import React from "react";

import InfoFirstSection from "./InfoFirstSection";

const FinalExamMain = () => {
  return (
    <>
      {/* <AboutPart /> */}

      <InfoFirstSection />

      {/*<InfoSecondSection />*/}

      {/*<InfoThirdSection />*/}

      {/*<LicenseSection />*/}

      {/*<BuilddingSection />*/}

      {/* <Feature /> */}

      {/* <Instructor /> */}
{/* 
      <Testimonial /> */}
    </>
  );
};

export default FinalExamMain;
